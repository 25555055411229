import React from 'react';
import { getUserLangKey } from 'ptz-i18n';
import { useSiteMetadata } from '../hooks/use-site-metadata'

const RedirectIndex = () => {
    const { langs, defaultLangKey } = useSiteMetadata().languages;
    const langKey = getUserLangKey(langs, defaultLangKey);

  if (typeof window !== 'undefined') {
    // Skip build, Browsers only
    const homeUrl = langKey ? `/${langKey}/` : `/en/`
    window.___replace(homeUrl)
  }

    return (<div />);
}

export default RedirectIndex;
